@keyframes openAnimation{
    from {max-width: 0%; opacity: 0.4;display: flex;}
    to {max-width: 100%;opacity: 1;display: flex;}
}

@keyframes closeAnimation{    
    0% {max-width: 250px;opacity: 1;display: flex;}
    95% {max-width: 50px;opacity: 0;display: flex;}
    100% {max-width: 50px; opacity: 0;display: none;}
}

.side-menu-container{
    max-width: 250px;
    width:100%;
    align-items: center;
    display: flex;
    flex-direction: column;
    position: fixed;
     background-color: rgba(110, 21, 118, 0.9);
    /* border-right: 1px solid rgb(206, 206, 206);  */
    left: 0px;
    top: 80px;
    bottom: 0px;
    box-sizing: border-box;
}



.side-menu-close{
    /*display: none;*/
    animation-name: closeAnimation;
    animation-duration: 0.5s;
    display: none;
}

.side-menu-open{
    display: flex;
    animation-name: openAnimation;
    animation-duration: 0.5s;
    animation-timing-function: ease-out;
}

.side-menu-backdrop{
    display:block;
    background-color: rgba(20, 20, 20, 0.4);
    backdrop-filter: blur(3px);
    position: fixed;
    top: 0px;
    width: 100vw;
    height: 100vh;
}

.side-menu-backdrop-show{
    display:block;
}

.side-menu-backdrop-hide{
    display:none;
}


@media screen and (min-width: 550px) {
    .side-menu-container {
        display: none;
       /*  box-shadow:none; */
       
    }

    /*.side-menu-backdrop{
        display:none;
    }*/

    .side-menu-backdrop-show{
        display:none;
    }

    .side-menu-close{       
        display: none;
        animation: none;
    }

    .side-menu-open{
        /*display: flex;*/
        animation: none;
    }
}

@media screen and (max-width: 550px) {
    .side-menu-container-horizontal {
        display: none;
       /*  box-shadow:none; */
       
    }
}



@media screen and (max-width: 550px) {
    .side-menu-container{
        top: 60px;
        max-width: 100%;
        background-color: rgba(255,255,255,0.7);
      
    }
}

.side-menu-links-container {
    list-style-type:none;
    /*padding:5px;*/
    padding-left:0px;
    margin: 0;
}

.side-menu-category-label {
    font-size: 16px;
    /* font-weight: bold;
    color:#333333; */
   /*  margin-top: 5px; */  
    padding: 10px 10px;  
    text-decoration: none;
    display: flex;
    justify-content: space-between;
    cursor:pointer;
}

.side-menu-horizontal-category-label {
    font-size: 13px;
    white-space: nowrap;
    /* font-weight: bold;
    color:#333333; */
   /*  margin-top: 5px; */  
    padding: 5px 6px;  
    text-decoration: none;
    display: flex;
    justify-content: space-between;
    cursor:pointer;
}




.side-menu-expand-icon {
    width:16px;
    height:16px;
    /* background-image: url("../../assets/images/expand-icon.png"); */
    background-repeat: no-repeat;  
}

.side-menu-category-label-active{    
    font-weight: bold;
    box-sizing: border-box;
    width: 100%;
    /* border-bottom: 1px solid #ab5335;
    border-top: 1px solid #ab5335;
    background-color: #ffcb97;
    background: linear-gradient(
135deg
, rgb(150, 251, 196) 0%, rgb(249, 245, 134) 100%); */
    padding: 5px 10px;
    color: #e28903;
    /*background-color: white;*/
   
    border-radius: 35px;
}

.side-menu-horizontal-category-label-active{ 
    font-weight: normal;

    box-sizing: border-box;
    /* width: 100%; */
    padding: 2px 13px;
    color: #f15a29;
    background-color: #fff5e1;
    border-radius: 35px;
}
.side-menu-category-label-inactive{   
    
    box-sizing: border-box;
    width: 100%;
    color:#222222;
    /* background-color: #21383a; */
    padding: 5px 13px;
    /*border-radius: 5px;*/
}

.side-menu-category-label-inactive:hover{   
    
    width: 100%;
    background-color: #fff5e1;;
    border-radius: 35px;
}


.side-menu-horizontal-category-label-inactive{   
    font-weight:normal;
    box-sizing: border-box;
    width: 100%;
    color:#242321;
    /* background-color: #21383a; */
    padding: 2px 13px;
    margin-right: 10px;
    /*border-radius: 5px;*/
}

.side-menu-horizontal-category-label-inactive:hover{       
    box-sizing: border-box;
    width: 100%;
    color: #f15a29;
    /* background-color: #8316b4; */
    border-radius: 30px;
    /* box-shadow: 2px 2px 6px -1px inset rgb(0 0 0 / 50%); */
    
}

.side-menu-links-item {
    box-sizing: border-box;
    width:100%;
    color:#222222;
    padding: 5px 20px;
    margin: 0;
    cursor: pointer;
}

.side-menu-links-item:hover{
    background:#EEEEEE;
    color:#222222; 
    font-weight:bold;
}

.side-menu-container-link {
    display: block;
    text-decoration: none;
    color: #464646;
    vertical-align: middle;
    /*padding: 5px 2px 5px 4px;
    margin: 0 -2px 0 -4px;
    position: relative;*/
    font-size:14px;

}

.side-menu-container-link:hover {
    /*color:#222222;*/
}

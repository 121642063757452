.item-card
{
    background-color: #fff1ff;
    box-shadow: 1px 1px 15px -4px rgba(20,20,20,0.4);
    width: 280px; 
    margin: 20px;
    padding:20px;
    border-radius: 10px;
}

.item-price
{
    color:green;
    font-weight: bold;
    font-size: 14px;
}

.item-description
{
    color:gray;
    font-size: 11px;
}

.item-title
{
    color:black;
    font-weight: bold;
    font-size: 16px;
}


.search-field{
    box-shadow: 1px 1px 15px -4px rgba(20,20,20,0.4);
    width: 100%;
    max-width: 500px;
    border-radius: 20px;
    border: 0px solid grey;
    font-size: 14px;
    height: 40px;
    padding: 10px 20px;
}

.banner-wrapper-01
{
    font-weight: bold;
    font-size: 30px;
    min-height: 200px;
    background: #f7e4c1;
    display: flex;
    justify-content: center;
    padding: 50px;
    align-items: center;
}

.button-01
{
    text-decoration: none;
    color: #f77609;
    background: white;
    box-shadow: 2px 2px 5px -2px;
    padding: 10px 30px;
    border-radius: 32px;
    font-size: 18px;
    box-shadow:  2px 9px -4px rgb(50 50 50 / 50%)
}

.info-card-wrapper 
{
    
    background: rgb(238, 238, 238);
    /* padding: 10px; */
    overflow: hidden;
    text-align: left;
    border-radius: 10px;
    margin: 21px;
    box-shadow: 2px 2px 12px -6px;
}

.info-card-numbering
{
    background: orange;
    width: 200px;
    font-size: 90px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    color: wheat;
    text-shadow: 1px 1px 1px rgb(10 10 10 / 20%);
}

.info-card-short-text
{
    max-width: 600px;
    width: 90%;
    padding: 20px;
    color: #21383a;
    text-align: justify;
    font-family: sans-serif;
    font-size: 12px;
    background: rgb(214 255 255);
    margin: 10px;
    border-radius: 10px;
    box-shadow: 2px 2px 4px -2px rgb(10 10 10 / 60%);
}